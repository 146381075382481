
import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap'
import '../App.css';

function VTSLink() {
  return (
    <div className="App-header" style={{ paddingTop: '80px' }}>

      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='blive' >
        <Card.Body>
          <Carousel>
            <Carousel.Item interval={1000}>
              <div style={{ height: '450px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                <img
                    src="assets/songchan-v1.png"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div style={{ height: '450px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                <img
                    src="assets/songchan-v2.png"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div style={{ height: '450px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                <img
                    className="w-100"
                    src="assets/songchan-1.png"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div style={{ height: '450px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                <img
                    className="w-100"
                    src="assets/songchan-2.png"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div style={{ height: '450px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                <img
                    className="w-100"
                    src="assets/songchan-3.png"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000}>
              <div style={{ height: '450px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', verticalAlign: 'middle' }}>
                <img
                    src="assets/songchan-4.png"
                />
              </div>
            </Carousel.Item>
          </Carousel>
          <Card.Title style={{lineHeight: "30px"}}>
            <b>点歌姬</b> (内测中) {' '}
            <Button variant="primary" size="sm" style={{ marginLeft: '5px' }} href="https://www.wolai.com/reito/wErrPM9DbM4NM6XrtkTWSL">前往软件介绍主页</Button>{' '}
            {/*<Button variant="success" size="sm" style={{ marginLeft: '5px' }} href="https://www.bilibili.com/video/BV1dk4y15793">介绍视频 </Button>{' '}*/}
          </Card.Title>
          <Card.Text>
            一个功能非常丰富，稳定好用美观的点歌姬！<br />
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '8px' }}></div>
            <Alert variant="primary">
              <span style={{marginRight: '10px', fontSize: '20px'}}>下载</span>
              <Button style={{marginRight: '5px'}} variant="primary" href={`https://dist.reito.fun/birth/点歌姬-win.zip`}>Windows</Button>{' '}
              <Button variant="primary" href={`https://dist.reito.fun/birth/点歌姬-osx.zip`}>macOS</Button>{' '}
              <div style={{ paddingTop: '5px' }}>如果使用出现问题，请加群：771477837，<a href="https://space.bilibili.com/2305653">B站私信</a>，QQ：534400134，邮箱：cnschwarzer@qq.com</div>
            </Alert>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default VTSLink;
