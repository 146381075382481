
import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap'
import '../App.css';

function Blive() {

  var version = '1.4.9'

  return (
    <div className="App-header" style={{ paddingTop: '80px' }}>

      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='blive' >
        <Card.Body>
          <Carousel style={{ paddingBottom: '10px' }}>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src="assets/1.jpg"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src="assets/2.png"
              />
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src="assets/3.png"
              />
            </Carousel.Item>
          </Carousel>
          <Card.Title>
            <b>弹幕姬</b>{' '}
            <Badge pill bg="primary">
              {version}
            </Badge>
            <Button variant="success" size="sm" style={{ marginLeft: '5px' }} href="https://www.bilibili.com/video/BV1jF411h75w">视频介绍</Button>{' '}
          </Card.Title>
          <Card.Text>
            一个优化视觉体验的B站弹幕姬，可延长每条弹幕在屏幕上的停留时间，Superchat与礼物感谢标记、自动保存加载未感谢条目。<br />
            <div style={{ paddingTop: '5px' }}>如果使用出现问题，请<a href="https://space.bilibili.com/2305653">B站私信</a>，QQ：534400134，邮箱：cnschwarzer@qq.com</div>
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '5px' }}></div>
            <Alert variant="primary">
              <Alert.Heading style={{fontSize: 20}}>下载最新版</Alert.Heading>
              <Button variant="outline-primary" href={`https://dist.reito.fun/birth/弹幕姬.zip`}>Windows</Button>{' '}
              <Button variant="outline-primary" href={`https://dist.reito.fun/birth/BliveAssist_${version}.apk`}>Android</Button>{' '}
              <Button variant="outline-primary" href={`https://apps.apple.com/cn/app/弹幕姬/id1587289539`}>iOS</Button>{' '}
              <Button variant="outline-primary" href={`https://apps.apple.com/cn/app/弹幕姬/id1587289539`}>macOS</Button>{' '}
              <br />

              <Alert.Heading style={{fontSize: 15, marginTop: '10px'}}>下载USB面捕连接助手</Alert.Heading>
              <Button variant="outline-primary" href={`https://dist.reito.fun/birth/弹幕姬面捕连接助手-win.zip`}>Windows</Button>{' '}
              <Button variant="outline-primary" href={`https://dist.reito.fun/birth/弹幕姬面捕连接助手-osx.zip`}>macOS</Button>{' '}
              <Button variant="outline-success" href={`https://github.com/reitovo/blive-face-relay`}>源代码</Button>{' '}
              <br />
            </Alert>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>更新日志</Accordion.Header>
              <Accordion.Body>
                <h5>1.3.5</h5>
                <h6>支持红包</h6>
                <h6>面捕有线连接</h6>
                <h5>1.3.4</h5>
                <h6>重制更新系统，推荐升级！</h6>
                <h5>1.3.3</h5>
                <h6>1. 新增：弹幕(列表)可以不折叠重复弹幕</h6>
                <h6>2. 新增：弹幕(列表)与弹幕(滚动)可以屏蔽所有表情弹幕</h6>
                <h6>3. 新增：主播可以自定义自己房间在弹幕姬中的一些元素，如用Logo替代历史记录中的文字 <a href='https://blive.reito.fun'> 前往体验 </a></h6>
                <h6>4. 修复：布局保存问题</h6>
                <h6>5. 修复：全面屏适配</h6>
                <h5>1.3.1</h5>
                <h6>1. 修复：部分房间无法使用</h6>
                <h6>2. 修复：布局无法保存</h6>
                <h5>1.3.0</h5>
                <h6>1. 新增：iOS端面捕插件参数编辑器，支持导入导出，以及导入VBridger配置</h6>
                <h6>2. 新增：点击左下角数据可查看弹幕曲线，以后还会添加更多数据项</h6>
                <h6>3. 新增：长按弹幕、礼物项可以复制、查看用户名的汉语拼音</h6>
                <h6>4. 新增：主界面右上角新增中英文切换</h6>
                <h6>5. 新增：帮助-调试可以进行日志上报</h6>
                <h6>6. 优化：底部功能栏布局</h6>
                <h6>7. 优化：礼物图标</h6>
                <h5>1.2.0</h5>
                <h6>1. 重新设计了礼物显示，添加礼物图标显示，添加了进入、分享、关注直播间事件，修复价格</h6>
                <h6>2. 添加了VTube Studio插件，可以直接驱动PC端VTS实现面捕捕捉，效果几乎与VBridger一样</h6>
                <h6>3. 在设置页增加了各种自定义项</h6>
                <h6>4. 问题修复与性能优化</h6>
                <h5>1.1.3</h5>
                <h6>修复：礼物金额错误</h6>
                <h6>新增：弹幕支持各类表情</h6>
                <h5>1.1.2</h5>
                <h6>修复：针对Windows、Android平台修复弹幕事件</h6>
                <h5>1.1.1</h5>
                <h6>新增：房间历史记录</h6>
                <h6>新增：高清化字体显示</h6>
                <h6>修复：付费礼物退出不保存</h6>
                <h5>1.1.0</h5>
                <h6>新增：经典滚动弹幕窗口、高能弹幕窗口</h6>
                <h6>新增：窗口可拖动、自由布局</h6>
                <h6>新增：帮助页面添加性能优化指南</h6>
                <h6>新增：长按弹幕/Superchat可以复制到剪贴板</h6>
                <h6>优化：设置、帮助页面功能优化</h6>
                <h6>优化：优化弹幕高度，使其可以铺满屏幕高度</h6>
                <h6>优化：自动删除被房管撤回的Superchat</h6>
                <h6>优化：设置中可以调整帧率，减少性能消耗</h6>
                <h5>1.0.8</h5>
                <h6>修复拖动SC、礼物列表时消失，增加滚轮移速</h6>
                <h5>1.0.7</h5>
                <h6>修复后台暂停</h6>
                <h6>任一模块现在可被完全隐藏</h6>
                <h5>1.0.6</h5>
                <h6>修复布局保存失效</h6>
                <h5>1.0.5</h5>
                <h6>修复弹幕速度</h6>
                <h5>1.0.4</h5>
                <h6>增加显示设置</h6>
                <h5>1.0.3</h5>
                <h6>修复窗口模式粉丝勋章异常显示</h6>
                <h6>增加重复弹幕色彩提示</h6>
                <h6>增强Websocket稳定性</h6>
                <h5>1.0.2</h5>
                <h6>修复界面滑块超出屏幕</h6>
                <h5>1.0.1</h5>
                <h6>增加界面放大倍率调节</h6>
                <h6>修复若干连接问题</h6>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>

    </div>
  );
}

export default Blive;
