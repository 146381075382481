import logo from './logo.svg';
import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap' 

function Gift() { 
  return (
    <div>
      <header className="App-header" style={{ paddingTop: '80px' }}> 
        <Card
          bg='Success'
          text='dark'
          className="mb-2 card-60"
          id='arcade'>
          <Card.Body>
            <Card.Title>
              <h3><b>出道回AR卡片</b></h3>
            </Card.Title>
            <Card.Text>
              <p>需要下载APP扫描卡片</p> 
              <Button variant="outline-primary" href={`https://cdn.reito.fun/Reito528.apk`}>Android</Button>{' '}
              <Button variant="outline-primary" href={`https://apps.apple.com/cn/app/铃当出道回/id1642297387`}>iOS</Button>{' '}
            </Card.Text> 
          </Card.Body>
        </Card>  
      </header>
    </div>
  );
}

export default Gift;
