import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Button, Badge, Alert, Card, Carousel, Accordion} from 'react-bootstrap'
import AppBar from './AppBar';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Privacy from './Privacy';
import Gift from './Gift';
import VTSLink from './Products/VTSLink'
import BLive from './Products/Blive'
import MusicGame from './Products/MusicGame'
import Sticker from './Products/Sticker'
import SongChan from "./Products/SongChan";

ReactDOM.render(
    <React.StrictMode>
        <AppBar/>
        <Router>
            <Route path={['/']} component={App} exact/>
            <Route path={['/vtslink']} component={VTSLink} exact/>
            <Route path={['/blive']} component={BLive} exact/>
            <Route path={['/mug']} component={MusicGame} exact/>
            <Route path={['/sticker']} component={Sticker} exact/>
            <Route path={['/privacy']} component={Privacy}/>
            <Route path={['/dc']} component={Gift}/>
            <Route path={['/songchan']} component={SongChan}/>
        </Router>
        <div className="App-header" style={{paddingTop: '0px'}}>
            <h6>铃当Reito</h6>
            <Button variant="outline-primary" href="https://beian.miit.gov.cn">苏ICP备17049545号</Button>{' '}
        </div>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
