
import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap'
import '../App.css';

function VTSLink() {
  return (
    <div className="App-header" style={{ paddingTop: '80px' }}>

      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='blive' >
        <Card.Body>
          <Carousel style={{ paddingBottom: '10px' }}>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src="assets/vts1.png"
              />
            </Carousel.Item>
          </Carousel>
          <Card.Title style={{lineHeight: "30px"}}>
            <b>VLink 联动！</b>{' '}
            <Button variant="primary" size="sm" style={{ marginLeft: '5px' }} href="https://www.wolai.com/reito/dGzCn2JJCB8tnZwWd6wcRN">前往软件介绍主页</Button>{' '}
            <Button variant="success" size="sm" style={{ marginLeft: '5px' }} href="https://www.bilibili.com/video/BV1dk4y15793">介绍视频

            </Button>{' '}
          </Card.Title>
          <Card.Text>
            一个可以让各种虚拟主播软件跨平台进行联动的工具。无需分享模型、低延迟、高性能、一键解决网络问题。<br />
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '8px' }}></div>
            <Alert variant="primary">
              <span style={{marginRight: '10px', fontSize: '20px'}}>下载</span>
              <Button variant="primary" href={`https://dist.reito.fun/birth/VLink.zip`}>Windows</Button>{' '}
              <div style={{ paddingTop: '5px' }}>如果使用出现问题，请加群：771477837，<a href="https://space.bilibili.com/2305653">B站私信</a>，QQ：534400134，邮箱：cnschwarzer@qq.com</div>
            </Alert>
            <Button variant="outline-primary" href={`https://github.com/reitovo/vlink-client`}>源代码</Button>{' '}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default VTSLink;
