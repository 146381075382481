import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap'
import '../App.css';

function MusicGame() {

  var version = '1.3.4'

  return (
    <div className="App-header" style={{ paddingTop: '80px' }}>

      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='layesta'>
        <Card.Body>
          <Card.Title>
            <b>Layesta</b>{' '}
            <Badge pill bg="primary" >
              1.4.5
            </Badge>
          </Card.Title>
          <Card.Text>
            Lanota 自制模拟器，支持在线谱面下载
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '5px' }}>下载</div>
            <Button variant="primary" href="https://www.taptap.com/app/159199">Android</Button>{' '}
            <Button variant="primary" href="https://itunes.apple.com/cn/app/id1381317457">iOS</Button>{' '}
          </div>
        </Card.Body>
      </Card>

      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='lanotalium'>
        <Card.Body>
          <Card.Title>
            <b>Lanotalium</b>{' '}
            <Badge pill bg="primary">
              2.5.0
            </Badge>
          </Card.Title>
          <Card.Text>
            使用 Lanotalium 创作属于你自己的 Lanota 谱面
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '5px' }}>下载</div>
            <Button variant="primary" href="https://gitee.com/Schwarzer/Lanotalium.Release.x64/repository/archive/master.zip">Windows</Button>{' '}
            <Button variant="primary" href="https://github.com/reitowo/Lanotalium">Source</Button>{' '}
            <Button variant="primary" href="https://timg.reito.fun/archive/schwarzer/Lanotalium%E6%89%8B%E5%86%8C.pdf">教程</Button>{' '}
          </div>
        </Card.Body>
      </Card>

      <Card
        bg='Success'
        text='dark'
        className="mb-2 card-60"
        id='arcade'>
        <Card.Body>
          <Card.Title>
            <b>Arcade</b>{' '}
            <Badge pill bg="primary">
              2.5.0
            </Badge>
          </Card.Title>
          <Card.Text>
            使用 Arcade 创作你的 Arcaea 谱面
          </Card.Text>
          <div class='button-wrapper'>
            <div style={{ paddingBottom: '5px' }}>下载</div>
            <Button variant="primary" href="https://timg.reito.fun/archive/arcade/ArcadeLanding.exe">Windows</Button>{' '}
            <Button variant="primary" href="https://gitee.com/Schwarzer/Arcade">Source</Button>{' '}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MusicGame;
