import logo from './logo.svg';
import { Button, Badge, Alert, Card, Carousel, Accordion } from 'react-bootstrap' 

function Privacy() {
 
  return (
    <div>
      <header className="App-header" style={{ paddingTop: '80px' }}> 
        <Card
          bg='Success'
          text='dark'
          className="mb-2 card-60"
          id='arcade'>
          <Card.Body>
            <Card.Title>
              <h3><b>隐私政策</b></h3>
            </Card.Title>
            <Card.Text>
              <p>在使用本人开发的软件时，可能会涉及您的隐私，对于如何处理有关内容的政策如下：</p>
              <h5>一、弹幕姬</h5>
              <p>1. 本软件的iOS版本提供VTube Studio（以下简称VTS）插件，通过使用由Apple提供的TrueDepth API，以及前置深度摄像头，获取您的面部捕捉数据，并发送至第三方软件VTube Studio。本软件以及VTS仅会对面捕数据进行实时处理，渲染Live2D人物，不会对此数据上传至任意其他第三方、储存、或用于其他任何用途。</p>
              <p>2. 本软件通过访问弹幕服务器获取的数据仅用于实时展示，并在获得您的同意的前提下对礼物、Superchat数据进行本地缓存，不会上传至任何远程服务器。</p> 
              <h5>二、VTube Studio 联动</h5>
              <p>1. 本软件会使用P2P技术建立连接，在此过程中，将使用您的IP地址作为建立连接，服务器仅会在建立连接时使用您的IP地址，在房间关闭后立即删除，不会进行储存。</p>
              <p>2. 本软件会在您开始分享 VTube Studio 画面后，发送至房主进行处理。同样的，房主合成画面后也会发送给所有参与者。在发送过程中服务器不会记录任何画面。</p>
              <p>3. 本软件会在崩溃时，向第三方错误收集平台 Backtrace.io 传输崩溃日志，仅限于崩溃的日志文件以及其他必要的信息。</p>
            </Card.Text> 
          </Card.Body>
        </Card>  
      </header>
    </div>
  );
}

export default Privacy;
