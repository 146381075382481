import logo from './logo.svg';
import {Button, Badge, Alert, Card, Carousel, Accordion} from 'react-bootstrap'
import './App.css';

function App() {
    return (
        <div className="App-header" style={{paddingTop: '80px'}}>

            <Card
                bg='Success'
                text='dark'
                className="mb-2 card-60">
                <Card.Body>
                    <Card.Title>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                <img style={{ width: '60%', alignContent: 'center' }} src="assets/logo.png"></img>
                            </div>
                            <b>这里是超天才猫猫科学家 铃当Reito！</b>
                        </div>
                    </Card.Title>
                    <div style={{paddingBottom: '5px'}}> 目前正在进行的工作：</div>
                    <Alert variant="primary" >
                        <div className="v-mid">
                            <img width='52px' style={{marginLeft: '12px', marginRight: '12px', marginBottom: '5px'}} src="assets/songchan.png"></img>
                            <Button className="btn-songchan" href="songchan" style={{marginLeft: '10px'}}>点歌姬</Button>
                        </div>
                        好用、功能非常丰富的点歌姬！
                    </Alert>
                    <Alert variant="primary" >
                        <div className="v-mid">
                            <img width='64px' style={{marginLeft: '4px', marginRight: '4px', marginTop: '-8px'}} src="assets/vlink.png"></img>
                            <Button className="btn-vlink" href="vtslink" style={{marginLeft: '10px'}}>VLink 联动</Button>
                        </div>
                        一个可以让各种虚拟主播软件进行联动的工具 (VTube Studio, Prprlive, Warudo...)
                    </Alert>
                    <Alert variant="primary">
                        <div className="v-mid">
                            <img width='52px' style={{marginLeft: '12px', marginRight: '12px', marginBottom: '5px'}} src="assets/blive.png"></img>
                            <Button className="btn-blive" href="blive" style={{marginLeft: '10px'}}>弹幕姬</Button>
                        </div>
                        一个优化视觉与直播体验的B站弹幕姬
                    </Alert>
                    <div style={{paddingBottom: '5px'}}> 其余历史作品请使用顶部导航栏~</div>

                    <Button className="btn-bili" href={`https://space.bilibili.com/2305653`}>哔哩哔哩</Button>{' '}
                    <Button className="btn-gh" href={`https://github.com/reitowo`}>Github</Button>{' '}
                    <Button variant="outline-primary" href={`https://www.wolai.com/reito/tDroPhLJH24g39nLYRNm8A`}>Blog</Button>{' '}
                </Card.Body>
            </Card>
        </div>
    );
}

export default App;
