import {Navbar, Nav} from 'react-bootstrap'

function AppBar() {
    return (
        <>
            <Navbar bg="light" style={{paddingLeft: '20px'}} fixed='top' expand='sm'>
                <Navbar.Brand href="/">铃当Reito</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/songchan">点歌姬</Nav.Link>
                        <Nav.Link href="/vtslink">VLink 联动</Nav.Link>
                        <Nav.Link href="/blive">弹幕姬</Nav.Link>
                        <Nav.Link href="/sticker">表情包处理</Nav.Link>
                        <Nav.Link href="/mug">音游工具</Nav.Link>
                        <Nav.Link href="/dc">数字礼物</Nav.Link>
                        <Nav.Link href="/privacy">隐私政策</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default AppBar;
